export const PATHS = {
  LEARN_SPACE: '/learn_spaces',
  LEARN_MINE: '/learns/:key?',
  PROJECT: '/projects/',
  PROJECT_REQUEST: '/projects/request_invests',
  PROJECT_MINE: '/projects/:key?',
  RECRUIT: '/recruit/:key?',
  EVENT: '/event/:key?',
  MUSIC_SPACE: '/music_space/',
  MUSIC_MINE: '/music_space/:key?',
  REPORT: '/reports/home',
  REPORT_MINE: '/report/:key?',
  CONFIG_MARKETPLACES: '/config_marketplaces',
  DEBT_LOGS: '/debt_logs/:debt_type',
  SHOP_REVENUES: '/shop_revenues/:status',
  PAYABLES_LOGS: '/payables_logs/:account_id',
  DEIAL_SHOP_REVENUES: '/shop_revenues/:status/:page_id',
  RECEIVABLES_LOGS: '/receivables_logs/:account_id',
  APPROVAL_PRODUCTS: '/approval_products/:key?',
  REPORT_VIOLATIONS: '/report_violations/:key?',
  CAMPAIGNS: '/campaigns/:key?',
  VOUCHERS: '/vouchers/:key?/:id?',
  PRODUCT: '/product/',
  MERCHANT_PAYMENT_REQUEST: '/merchant_payment_requests/',
  PROMOTION: '/promotion/:key?',
  NOTI_MKP: '/noti_mkp/:key?',
  CALENDAR_CAMPAIGNS: '/calendar_campaigns',
  POPUP_LANDING_PAGE: '/popup_landing_page/:key?',
  BANNER_SHOP_MALL: '/banner_shop_mall/:key?',
  ORDER_FAILED: '/order_failed',
  REFUND_REQUESTS: '/refund_requests',
  POST_CHART: '/post_chart/',
  FEED: '/',
  TRENDING: '/trending',
  APPROVAL_BRAND: '/approval_brand/:key?',

  COMPLAIN: '/complains',
  COMPLAIN_REPORT: '/complain_reports',
  BLOOD_DONNATIONS: '/blood_donations',
  ENABLE_MONEY: '/enable_money',
  ENABLE_MONEY_USERS: '/enable_money/users',
  ENABLE_MONEY_PAGES: '/enable_money/pages',
  REPORT_SYSTEM_ERROR: '/system_errors/',
  VERIFY: '/verify/',
  VERIFY_SUBJECT: '/verifies/:key?',
  ADVERTISING_ACCOUNT: '/advertising_account/',
  FINANCIAL_CONTROL: '/financial_control/',
  LOGIN: '/login',
  TEST: '/test'
};
